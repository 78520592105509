import React from "react"

import { DownloadButtonStyled } from "./DownloadButton.styled"
import { DownloadButtonProps } from "./DownloadButton.types"

const DownloadButton = React.memo((props: DownloadButtonProps) => {
  const { href, onClick, label, place, isFullWidth } = props
  return (
    <DownloadButtonStyled
      variant="contained"
      data-place={place}
      href={href}
      onClick={onClick}
      fullWidth={isFullWidth}
    >
      {label}
    </DownloadButtonStyled>
  )
})

export { DownloadButton }
