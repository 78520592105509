import React from "react"

import { Typography } from "decentraland-ui2"

import { ContentfulSocialProofBadgeEntryFieldsProps } from "../../../hooks/useLandingContentfulQuery"
import {
  SocialProofCardBadgeContainer,
  SocialProofCardContainer,
} from "./LandingSocialProofCard.styled"

const LandingSocialProofCardBadge = React.memo(
  (props: ContentfulSocialProofBadgeEntryFieldsProps) => {
    const { text, badgeCount } = props
    return (
      <SocialProofCardContainer>
        <SocialProofCardBadgeContainer>
          <Typography variant="h4">{badgeCount}</Typography>
          <Typography variant="h6">{text.text}</Typography>
        </SocialProofCardBadgeContainer>
      </SocialProofCardContainer>
    )
  }
)

export { LandingSocialProofCardBadge }
