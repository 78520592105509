import React from "react"

import { Avatar } from "decentraland-ui2"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { ContentfulSocialProofQuoteEntryFieldsProps } from "../../../hooks/useLandingContentfulQuery"
import {
  SocialProofCardContainer,
  SocialProofCardQuoteContainer,
  SocialProofCardQuoteText,
  SocialProofCardUserContainer,
  SocialProofCardUserName,
} from "./LandingSocialProofCard.styled"

const LandingSocialProofCardQuote = React.memo(
  (props: ContentfulSocialProofQuoteEntryFieldsProps) => {
    const { text, userName, userAvatar, quoteBackground } = props
    const userAvatarOptimized = useImageOptimization(userAvatar.url)!
    return (
      <SocialProofCardContainer>
        <SocialProofCardQuoteContainer
          sx={{ backgroundColor: quoteBackground }}
        >
          <SocialProofCardQuoteText>"{text.text}"</SocialProofCardQuoteText>
          <SocialProofCardUserContainer>
            <Avatar
              sx={{ width: 34, height: 34 }}
              src={
                (isWebpSupported() && userAvatarOptimized.webp) ||
                userAvatarOptimized.jpg ||
                userAvatarOptimized.optimized
              }
            />
            <SocialProofCardUserName>{userName}</SocialProofCardUserName>
          </SocialProofCardUserContainer>
        </SocialProofCardQuoteContainer>
      </SocialProofCardContainer>
    )
  }
)

export { LandingSocialProofCardQuote }
