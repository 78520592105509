import React from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import {
  WhatsHotSlide,
  WhatsHotSlideActionsContainer,
  WhatsHotSlideButton,
  WhatsHotSlideContainer,
  WhatsHotSlideContent,
  WhatsHotSlideImageContainer,
  WhatsHotSlideSubtitle,
  WhatsHotSlideTextContainer,
  WhatsHotSlideTitle,
} from "./LandingWhatsHotSlide.styled"
import { LandingWhatsHotSlideProps } from "./LandingWhatsHotSlide.types"

const LandingWhatsHotSlide = React.memo((props: LandingWhatsHotSlideProps) => {
  const { title, subtitle, image, buttonLink, buttonLabel, id } = props

  const handleMainCTA = useTrackLinkContext()

  const imageOptimized = useImageOptimization(image.url)

  return (
    <WhatsHotSlideContainer>
      <WhatsHotSlide data-index={id}>
        <WhatsHotSlideContent>
          <WhatsHotSlideImageContainer
            imageUrl={
              (isWebpSupported() && imageOptimized.webp) ||
              imageOptimized.jpg ||
              imageOptimized.optimized
            }
          />
        </WhatsHotSlideContent>
      </WhatsHotSlide>
      <WhatsHotSlideTextContainer>
        <WhatsHotSlideTitle variant="h3" sx={{ typography: { sx: "h5" } }}>
          {title}
        </WhatsHotSlideTitle>
        <WhatsHotSlideSubtitle
          variant="h4"
          sx={{ typography: { sx: "body1" } }}
        >
          {subtitle.subtitle}
        </WhatsHotSlideSubtitle>
        <WhatsHotSlideActionsContainer>
          <WhatsHotSlideButton
            href={buttonLink}
            onClick={handleMainCTA}
            color="secondary"
            variant="outlined"
            fullWidth={true}
            data-title={title}
            data-subtitle={subtitle.subtitle}
            data-image={image.url}
          >
            {buttonLabel}
          </WhatsHotSlideButton>
        </WhatsHotSlideActionsContainer>
      </WhatsHotSlideTextContainer>
    </WhatsHotSlideContainer>
  )
})

export { LandingWhatsHotSlide }
