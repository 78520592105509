import {
  Box,
  ImageListItem,
  Skeleton,
  Typography,
  styled,
  useTheme,
} from "decentraland-ui2"

import Video from "../../Video/Video"

const SocialProofCardContainer = styled(ImageListItem)({
  borderRadius: "8px",
  overflow: "hidden",
})

const SocialProof = styled(Box)(() => {
  const theme = useTheme()
  return {
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    paddingTop: "0",
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      right: "50%",
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
      zIndex: 1,
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
      paddingBottom: "70px",
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "50%",
        bottom: 0,
        right: 0,
        backgroundImage:
          "linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
      },
    },
  }
})

const SocialProofContent = styled(Box)({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
})

const SocialProofContentLoading = styled(Skeleton)({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  transform: "none",
  transformOrigin: "none",
})

const SocialProofCardTextContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasBackground",
})((props: { hasBackground: boolean }) => {
  const { hasBackground } = props
  const theme = useTheme()
  let hasBackgroundStyle = {}
  if (hasBackground) {
    hasBackgroundStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 2,
    }
  }

  return {
    ...hasBackgroundStyle,
    marginLeft: "50px",
    marginRight: "50px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "20px",
      marginRight: "20px",
    },
  }
})

const SocialProofCardQuoteContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "22px 17px",
  minHeight: "190px",
})

const SocialProofCardBadgeContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "22px 17px",
  minHeight: "190px",
  background: "linear-gradient(243.96deg, #FF2D55 -11.67%,#FFBC5B 88.23%)",
})

const SocialProofCardUserContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
})

const SocialProofCardQuoteText = styled(Typography)({
  fontSize: "14.22px",
  fontWeight: 500,
  lineHeight: "22.76px",
  color: "#161518",
})

const SocialProofCardUserName = styled(Typography)({
  marginLeft: "5.7px",
  color: "#161518",
})

const SocialProofTitle = styled(Typography)({})

const SocialProofCardImage = styled("img")({
  width: "100%",
})

const SocialProofCardVideo = styled(Video)(() => {
  return {
    "@media (max-aspect-ratio: 16 / 9)": {
      "&.Video": {
        width: "100%",
        height: "100%",
      },
    },
    "@media (min-aspect-ratio: 16 / 9)": {
      "&.Video": {
        width: "100%",
        height: "100%",
      },
    },
  }
})

export {
  SocialProofCardContainer,
  SocialProof,
  SocialProofContent,
  SocialProofContentLoading,
  SocialProofCardTextContainer,
  SocialProofCardQuoteContainer,
  SocialProofCardBadgeContainer,
  SocialProofCardUserContainer,
  SocialProofCardQuoteText,
  SocialProofCardUserName,
  SocialProofTitle,
  SocialProofCardImage,
  SocialProofCardVideo,
}
