import { Box, Typography, styled, useTheme } from "decentraland-ui2"

const SocialProofSection = styled("section")(() => {
  const theme = useTheme()
  return {
    height: "80vh",
    width: "100%",
    padding: 0,
    margin: 0,
    position: "relative",
    minHeight: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 15,
    [theme.breakpoints.down("xs")]: {
      height: "90vh",
      minHeight: "90vh",
    },
  }
})

const SocialProofContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    height: "100%",
    overflow: "scroll",
    marginLeft: "160px",
    marginRight: "160px",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "80px",
      marginRight: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "8px",
      marginRight: "8px",
    },
  }
})

const SocialProofTitle = styled(Typography)({
  marginBottom: "48px",
})

export { SocialProofSection, SocialProofContainer, SocialProofTitle }
